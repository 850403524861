@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Black+Ops+One&family=Poppins:wght@600&display=swap');

::-webkit-scrollbar {
  width: 5px;
  /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
  /* color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the thumb */
  border-radius: 0px;
  /* roundness of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* color of the thumb on hover */
}

.inner-shadow-example {
  box-shadow: inset 0 5px 10px rgba(100, 100, 100, 0.2);
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #4e6378; */
  background-color: white;
}

.center .inputbox {
  position: relative;

}

.center input {
  font-size: 20px;
  padding: 10px;
  border: 1px solid black;
}

.center span {
  position: absolute;
  left: 1.9rem;
  top: 1.6rem;
  text-transform: uppercase;
  pointer-events: none;
  color: #555;
  transition: 0.2s;
}

.center input:valid~span,
.center input:focus~span {
  color: rgb(0, 0, 0);
  transform: translate(-5px, -18px);
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px 0 10px
}

.button {
  transition: 0.4s;
}

.addform {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.front {
  background-attachment: fixed;
}